<template>    
    <v-overlay class="align-items-center text-center">
        <v-progress-circular class="mx-auto" indeterminate size="64" />
        <p>Authenticating</p>
    </v-overlay>                
</template>

<script>    
    export default {
        name: 'Authentication',               
        async mounted() {              
            await this.$BlitzIt.auth.authenticate(this.$route.query);
        },
    }
</script>